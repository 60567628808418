<template>
  <basic-modal :modal-id="'delete-wine-modal'" :size="'md'">
    <template #title>
      {{ $t('detailPanel.deleteModal.title') }}
    </template>
    <div class="spacer s"></div>
    <h3>{{$t('detailPanel.deleteModal.text')}}</h3>
    <template #footer>
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button size="sm" variant="danger" @click="handleCancel">
          {{$t('general.buttons.cancel')}}
        </b-button>
        <b-button size="sm" variant="success" @click="handleDelete">
          {{$t('detailPanel.deleteModal.confirmButton')}}
        </b-button>
    </template>
  </basic-modal>
</template>
<script>
import BasicModal from '@/components/modals/BasicModal.vue'

export default {
  name: 'DeleteWineModal',
  data() {
    return {
      loading: false
    }
  },
  components: {
    BasicModal
  },
  props: {

  },
  created() {

  },
  computed: {
  },
  methods: {
    handleDelete() {
      this.$emit('confirmDelete')
    },
    handleCancel() {
      this.$emit('cancelDelete')
    }
  }
}
</script>
<style scoped></style>
