<template>
	<div>
		<b-row>
			<b-col cols="6">{{$t('general.supplier')}}</b-col>
			<b-col cols="6">{{$t('general.ek_short')}}</b-col>
		</b-row>
		<div class="mb-3">
			<b-row class="supplierRow" v-for="purchasePrice in purchasePrices" v-bind:key="purchasePrice.id">
					<b-col cols="6"><span>{{ wineSupplierText(purchasePrice.wineSupplier.data.id) }}</span></b-col>
					<b-col cols="6">
						<span class="smallText">{{$t('general.netPrice')}}: </span>
						<span class="currency">{{ purchasePrice.price.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })  }} {{currency}}</span>
						<span class="smallText">{{$t('general.grossPrice')}}: </span>
						<span class="currency">{{ Number(getGrossPrice(purchasePrice.price)).toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }} {{currency}}</span>   
					</b-col>
			</b-row>
      
		</div>
	</div>
</template>

<script>

import { getGrossPrice, getWineSupplierText } from '@/utils/functions'

export default {
		name: 'PurchasePrices',
		props: {
			venueSupplierOptions: Array,
			purchasePrices: Array,
			currency: String
		},
		methods: {
			getGrossPrice,
			wineSupplierText(wineSupplierId) {
				const generalText = this.$t('general.dropdownText')
				return getWineSupplierText(generalText, wineSupplierId, this.venueSupplierOptions)
			}
		}
}

</script>