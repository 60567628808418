<template>
  <span v-if="purchasePriceAvg && sellingPrice">
    {{ Number((getNetPrice(sellingPrice) - purchasePriceAvg)).toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }} {{currency}}
  </span>
  <span v-else>
    -
  </span>
</template>

<script>

import { getNetPrice } from '@/utils/functions'

export default {
  name: 'NetProfit',
  props: {
    purchasePriceAvg: Number,
    sellingPrice: Number,
    currency: String
  },
  data() {
    return {
    }
  },
  methods: {
    getNetPrice
  },
}

</script>